import { CircleClose, Plus } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import { getLocalStorageWithExpiry } from '@/core/utils/common';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { USER_PUBLIC_GALLERY_CACHE, USER_PUBLIC_PROFILE_CACHE } from '@/store/modules/attachment-cache/constants';
import { AUTH_TOKEN } from '../../../core/constants';
export default defineComponent({
    name: 'public-profile-form-component',
    emits: ['submit'],
    components: {
        Plus,
        CircleClose
    },
    props: {
        record: {
            type: Object,
            required: true,
        },
        submitting: Boolean,
        disabled: Boolean,
    },
    data: () => {
        return {
            rules: {
                description: [
                    { required: true, message: 'Add a description.', trigger: 'blur' },
                ],
                profileImage: [
                    { required: true, message: 'Add a profile image.', trigger: 'change' },
                ],
            },
            apiUrl: process.env.VUE_APP_API_URL,
            fileList: [],
            profileImage: null,
            galleryImageUrls: [],
            uploadingProfileImage: false,
            uploadingGalleryImages: false,
            UserVerificationStatusEnum,
            newUploadedImageKey: '',
            isGalleryLoaded: true,
            isProfileImageLoaded: false
        };
    },
    computed: {
        uploadHeaders() {
            const token = getLocalStorageWithExpiry(AUTH_TOKEN);
            return {
                Authorization: `Bearer ${token}`
            };
        },
        formEntity() {
            return this.record;
        }
    },
    watch: {
        'record.galleries': {
            deep: true,
            async handler(newGallery) {
                if (!this.galleryImageUrls.length && newGallery) {
                    await this.loadGalleryImageUrls(newGallery);
                }
            }
        },
        'record.profileImage': {
            deep: true,
            handler(profileImage) {
                if (profileImage) {
                    this.newUploadedImageKey = profileImage;
                    this.loadProfileImageUrl(profileImage);
                }
            }
        }
    },
    methods: {
        ...mapActions(ATTACHMENT_CACHE_STORE, [
            'getAttachments',
            'resetCacheEntity'
        ]),
        loadProfileImageUrl(value) {
            this.getAttachments({
                name: USER_PUBLIC_PROFILE_CACHE,
                attachments: [{ attachment: value }],
                returnFirstOnly: true,
            })
                .then(async ({ src }) => {
                this.profileImage = src;
                this.isProfileImageLoaded = true;
            })
                .catch(() => { })
                .finally(() => {
                this.isProfileImageLoaded = true;
            });
        },
        loadGalleryImageUrls(list) {
            this.isGalleryLoaded = false;
            this.getAttachments({
                name: USER_PUBLIC_GALLERY_CACHE,
                attachments: list
            })
                .then((values) => {
                this.galleryImageUrls = values;
                this.isGalleryLoaded = true;
            })
                .catch(() => { })
                .finally(() => {
                this.isGalleryLoaded = true;
            });
        },
        removeGalleryImage(index) {
            this.galleryImageUrls.splice(index, 1);
            this.formEntity.galleries.splice(index, 1);
        },
        handleErrorFiles(err) {
            this.$notify.error({
                title: 'Upload Error',
                message: err || 'Uploading files error.'
            });
        },
        onProgressUploadProfileImage() {
            this.uploadingProfileImage = true;
        },
        onProgressUploadGalleryImages() {
            this.uploadingGalleryImages = true;
        },
        updateProfileImageOnSuccess(response) {
            this.profileImage = null;
            this.newUploadedImageKey = response.attachment;
            this.profileImage = response.src;
            this.uploadingProfileImage = false;
        },
        async updateGalleryFileOnSuccess(response, file) {
            const currentFile = file;
            if (this.record.galleries == null) {
                this.formEntity.galleries = [];
            }
            this.formEntity.galleries.push(response);
            this.galleryImageUrls.push(response);
            currentFile.record = response;
            this.uploadingGalleryImages = false;
        },
        handleRemoveGalleryFile(file, fileList) {
            this.formEntity.galleries.splice(this.record.galleries.indexOf(file.record));
            this.fileList = fileList;
        },
        submitForm() {
            this.isProfileImageLoaded = true;
            this.formEntity.profileImage = this.newUploadedImageKey;
            this.$refs.tpPublicProfileForm.validate((valid) => {
                if (valid) {
                    this.$emit('submit', this.record);
                    this.resetCacheEntity(USER_PUBLIC_PROFILE_CACHE);
                    this.resetCacheEntity(USER_PUBLIC_GALLERY_CACHE);
                    return true;
                }
                return false;
            });
        }
    }
});
