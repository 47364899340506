import { defineComponent } from 'vue';
import ChipState from '@/core/components/ui/ChipStateDepracated.vue';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
export default defineComponent({
    name: 'user-verification-status-chip',
    components: {
        ChipState,
    },
    props: {
        verificationState: {
            required: true,
            type: String,
            default: '0',
        }
    },
    data() {
        return {
            UserVerificationStatusEnum: {
                ...UserVerificationStatusEnum,
            }
        };
    },
});
