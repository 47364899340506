import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import UserVerificationStatus from '@/core/components/account/UserVerificationStatus.vue';
import CardWrapper from '@/core/components/ui/CardWrapper.vue';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import PublicProfileForm from '@/modules/account-details/components/PublicProfileFormComponent.vue';
import { USERS_STORE } from '../../../store/modules/users';
export default defineComponent({
    name: 'profile-page',
    components: {
        CardWrapper,
        UserVerificationStatus,
        PublicProfileForm,
    },
    data() {
        return {
            submittingForm: false,
            UserVerificationStatusEnum,
            publicProfile: {
                description: '',
                profileImage: null,
                galleries: [],
            },
            publicProfileUserVerificationStatus: UserVerificationStatusEnum.NOT_ADDED
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['user']),
        disabled() {
            return this.user.userVerificationStatus === UserVerificationStatusEnum.PENDING_VERIFICATION;
        }
    },
    created() {
        if (this.user.userPublicProfile) {
            this.getUserPublicProfile();
        }
    },
    methods: {
        ...mapActions(USERS_STORE, [
            'getUserProfile',
            'saveUserProfile',
            'getAccountStatuses'
        ]),
        ...mapActions(USERS_STORE, ['getUser', 'saveUserState']),
        getUserPublicProfile() {
            if (this.user.userPublicProfile) {
                this.getUserProfile({ userId: this.user.id, userPublicProfileId: this.user.userPublicProfile.id })
                    .then((response) => {
                    this.publicProfile = {
                        description: response.description,
                        profileImage: response.profileImage,
                        galleries: response.galleries,
                    };
                    this.publicProfileUserVerificationStatus = response.userVerificationStatus;
                });
            }
        },
        nextSection() {
            const publicProfile = this.$refs.publicProfileForm;
            publicProfile.submitForm();
        },
        submit(formData) {
            this.submittingForm = true;
            const payload = {
                userId: this.user.id,
                profile: formData,
            };
            this.saveUserProfile(payload)
                .then(async () => {
                this.$notify.success({
                    type: 'success',
                    message: 'Public profile successfully saved.'
                });
                this.getUserPublicProfile();
                this.saveUserState(await this.getUser(this.user.id));
                this.getAccountStatuses();
                this.$router.push({ name: 'account/business-details' });
            })
                .catch((e) => {
                this.$notify.error({
                    message: e || 'Error submitting Public Profile details.'
                });
            })
                .finally(() => {
                this.submittingForm = false;
            });
        }
    }
});
